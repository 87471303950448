import { WindowLocation } from "@reach/router";
import { graphql, navigate } from "gatsby";
import React, { useCallback } from "react";
import Card from "../components/Card/Card";
import Credits from "../components/Credits/Credits";
import DownloadLink from "../components/DownloadLink/DownloadLink";
import EditorEmbed from "../components/EditorEmbed/EditorEmbed";
import EditorEmbedSupport from "../components/EditorEmbedSupport/EditorEmbedSupport";
import PdfEmbed from "../components/PdfEmbed/PdfEmbed";
import WithSpacing from "../components/Spacing/Spacing";
import StepperBarInternal from "../components/Stepper/StepperBar/StepperBarInternal";
import Text from "../components/Text/Text";
import UnitOfWorkLayout from "../components/UnitOfWorkLayout/UnitOfWorkLayout";
import PageLayout from "../layouts/PageLayout/PageLayout";
import {
  CommonContext,
  MicrobitCode,
  SanityCampaign,
  SanityPage
} from "../model/common";
import {
  DownloadCategory,
  SanityLesson,
  SanityUnitOfWork
} from "../model/lessons";
import { GlobalMenus } from "../model/menu";
import { urlForDocument } from "../urls";
import { SiteArea } from "../utils/analytics";
import { downloadUrl } from "../utils/cdn";
import { completedCodeKey } from "../utils/defaults";
import { findLessonNumber, lessonDownloadName } from "../utils/downloads";
import styles from "./LessonPage.module.scss";

export const pageQuery = graphql`
  query LessonPage($_id: String!, $unitId: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    listings: sanityUnitOfWorkListingPage {
      _id
      _type
      title
    }
    lesson: sanityLesson(_id: { eq: $_id }) {
      _id
      _type
      title
      slug {
        current
      }
      _rawIntroduction(resolveReferences: { maxDepth: 5 })
      _rawLearningObjectives(resolveReferences: { maxDepth: 5 })
      ...LessonTopics
      completedCode {
        _key
        name
        editors
        scratch {
          url
          file {
            asset {
              originalFilename
              extension
              path
            }
          }
          scratchImages {
            asset {
              ...SanityImageAsset
            }
          }
        }
        makecode {
          project
          url
        }
        python {
          main
        }
      }
      image {
        ...SanityImage
      }
      downloads {
        _key
        name
        category
        file {
          asset {
            originalFilename
            extension
            path
          }
        }
        pdf {
          asset {
            originalFilename
            extension
            path
          }
        }
      }
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
      fields {
        editorLinks
      }
    }
    unit: sanityUnitOfWork(_id: { eq: $unitId }) {
      ...SanityUnitOfWorkForListing
      contents {
        downloads {
          category
          name
        }
      }
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
      contents {
        _id
        _type
        title
        slug {
          current
        }
      }
      ...SanityUnitOfWorkRelatedField
      sponsor {
        ...ExternalCollaborator
      }
      licence {
        ...Licence
      }
    }
  }
`;

const LessonPage = ({
  pageContext,
  data: { menus, unit, lesson, listings },
  location
}: LessonPageProps) => {
  const { title } = lesson;
  return (
    <EditorEmbedSupport document={lesson}>
      <PageLayout
        siteArea={SiteArea.LESSONS}
        metadata={{
          title,
          alternates: pageContext.alternates,
          page: lesson
        }}
        menus={menus}
        strings={pageContext.strings}
        location={location}
      >
        <UnitOfWorkLayout
          listings={listings}
          unit={unit}
          lesson={lesson}
          details={<LessonDetails unit={unit} lesson={lesson} />}
        />
      </PageLayout>
    </EditorEmbedSupport>
  );
};

const LessonDetails = ({
  unit,
  lesson
}: {
  unit: SanityUnitOfWork;
  lesson: SanityLesson;
}) => {
  const lessonIndex = findLessonNumber(unit, lesson) - 1;
  const nextLesson = unit.contents[lessonIndex + 1];
  const isLastLesson = lessonIndex === unit.contents.length - 1;
  const handleClick = useCallback(() => {
    navigate(urlForDocument(nextLesson));
  }, [nextLesson]);
  return (
    <Card type="main">
      <WithSpacing>
        <div>
          <DownloadsSection
            unit={unit}
            lesson={lesson}
            title="Lesson plan preview"
            defaultDownloadName="Lesson plan"
            category="plan"
          />
          <DownloadsSection
            unit={unit}
            lesson={lesson}
            title="Slides preview"
            defaultDownloadName="Slides"
            category="slides"
          />
          <DownloadsSection
            unit={unit}
            lesson={lesson}
            title="Student handouts"
            defaultDownloadName="Student handout"
            category="student-handouts"
            embed={false}
          />
          {lesson.completedCode && (
            <CompletedCodeSection unit={unit} lesson={lesson} />
          )}
        </div>
      </WithSpacing>
      <Credits licence={unit.licence} />
      {!isLastLesson && (
        <StepperBarInternal
          stepNumber={lessonIndex + 2}
          buttonText={
            unit.unitType === "designChallenge"
              ? "Next activity"
              : "Next lesson"
          }
          onClick={handleClick}
        />
      )}
    </Card>
  );
};

const DownloadsSection = ({
  title,
  defaultDownloadName,
  category,
  lesson,
  unit,
  embed = true
}: {
  unit: SanityUnitOfWork;
  lesson: SanityLesson;
  title: string;
  defaultDownloadName: string;
  category: DownloadCategory;
  embed?: boolean;
}) => {
  const downloads = lesson.downloads.filter(d => d.category === category);
  const preview = downloads.filter(d => d.pdf);
  return downloads.length === 0 ? null : (
    <>
      <Text variant="h2">{title}</Text>
      {embed &&
        preview.map((preview, i) => (
          <PdfEmbed
            key={i}
            id={preview._key}
            title={title + " file embed"}
            src={downloadUrl(preview.pdf.asset, {
              showInline: true,
              overrideFilename: preview.name ? preview.name : undefined
            })}
          />
        ))}
      <div className={styles.downloads}>
        <ul>
          {downloads.map((download, i) => (
            <li key={i}>
              <DownloadLink
                to={downloadUrl(download.file.asset, {
                  overrideFilename: stripExtension(
                    lessonDownloadName(unit, lesson, download)
                  )
                })}
                fileType={download.file.asset.extension}
                text={download.name ?? defaultDownloadName}
              />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

const CompletedCodeSection = ({
  unit,
  lesson
}: {
  unit: SanityUnitOfWork;
  lesson: SanityLesson;
}) => {
  const code: MicrobitCode = {
    ...lesson.completedCode!,
    // Use the manually provided a key to provide the correct code links.
    // See on-create-node.ts for assignment and CodeLinks in EditorEmbed.tsx for use.
    _key: completedCodeKey
  };
  return (
    <>
      <Text variant="h2">Completed lesson code</Text>
      <Text variant="defaultLight">
        This provides an example of the code created during this lesson.
      </Text>
      <EditorEmbed unit={unit} code={code} context={lesson} />
    </>
  );
};

const stripExtension = (p: string): string => p.slice(0, p.lastIndexOf("."));

export default LessonPage;

interface LessonPageProps {
  data: {
    menus: GlobalMenus;
    unit: SanityUnitOfWork;
    lesson: SanityLesson;
    listings: SanityPage;
    campaigns: { nodes: SanityCampaign[] };
  };
  location: WindowLocation;
  pageContext: CommonContext & { unitId: string };
}
